<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="listdrug"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ข้อมูลรายชื่อคุ้ม.xls"
        :header="ข้อมูลรายชื่อคุ้ม"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        <span>Download ข้อมูลรายชื่อคุ้ม</span>
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["listdrug"],
  data() {
    return {
      json_fields: {
        สถานี: "station",
        ชื่อคุ้ม: "gangName",
        "ชื่อ-สกุล": "name",
        อายุ: "age",
        หมายเลขโทรศัพท์: "phone",
        ตำแหน่ง: "position",
        // ไม่ได้กรอก: "notInput",
        //  { text: "ลำดับ", value: "count", align: "center" },
        // { text: "ชื่อคุ้ม", value: "station", align: "center" },
        // { text: "ชื่อ-สกุล", value: "name", align: "center" },
        // { text: "อายุ", value: "age", align: "center" },
        // { text: "หมายเลขโทรศัพท์", value: "phone", align: "center" },
        // { text: "ตำแหน่ง", value: "position", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center", width: "180px" },
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      listdrug: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.listdrug = response.data.data[0];
    // },
  },
};
</script>
